import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { func } from 'prop-types'
import styles from './Navigation.module.scss'

export const Navigation = ({ handleOnClick }) => {
  const { data } = useSelector(({ authStore }) => authStore.authorizedUser)

  return (
    <div className={styles.wrapper}>
      <Link to="/all-sessions" onClick={handleOnClick}>
        Wszystkie nagrania
      </Link>
      <a href="https://nastrazy.org/" target="_blank" rel="noreferrer">
        Nastrazy.org
      </a>
      {data && (
        <Link to="/dashboard" onClick={handleOnClick}>
          Panel użytkownika
        </Link>
      )}
      {/* <Link to="/all-sessions">Zgłoś problem</Link> */}
    </div>
  )
}
Navigation.propTypes = {
  handleOnClick: func.isRequired,
}
